import axios from 'axios';

// Создаем экземпляр axios
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://secure-dashboard-xyz789.webtransfer.app/',
  withCredentials: true, // Если используете cookies
});

// Добавляем interceptors для запросов
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Добавляем interceptors для ответов
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      try {
        // Попытка обновить токен
        const refreshResponse = await apiClient.post('/node/api/refresh-token');
        const newToken = refreshResponse.data.token;

        // Сохраняем новый токен
        localStorage.setItem('jwtToken', newToken);

        // Повторяем оригинальный запрос
        error.config.headers.Authorization = `Bearer ${newToken}`;
        return apiClient(error.config);
      } catch (refreshError) {
        console.error('Ошибка при обновлении токена:', refreshError);
        // Если обновление токена не удалось, редиректим на страницу логина
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export default apiClient;
