// src/middleware/websocketMiddleware.js
import { connect, disconnect, receiveMessage } from '../actions/slices/websocketSlice';

export const websocketMiddleware = store => next => action => {
  // Подключение WebSocket
  if (action.type === 'websocket/connect') {
    const ws = new WebSocket('wss://webtransfer.app/node');

    ws.onopen = () => {
      store.dispatch(connect());
      console.log('WebSocket connected');
    };

    ws.onmessage = (event) => {
      store.dispatch(receiveMessage(event.data));
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = () => {
      store.dispatch(disconnect());
      console.log('WebSocket disconnected');
    };

    return;
  }

  return next(action);
};
