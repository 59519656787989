import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchDashboardApi } from '../../api/api';

export const fetchDashboardData = createAsyncThunk(
  'dashboard/fetchDashboardData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchDashboardApi();
      return response ;
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      return rejectWithValue(error.response?.data || 'Ошибка загрузки данных');
    }
  }
);

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const dashboardReducer = dashboardSlice.reducer;
