import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchNotificationsApi } from '../../api/api';

// Асинхронный thunk для загрузки уведомлений
export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchNotificationsApi();
      return response.notifications; // Предполагается, что API возвращает объект notifications
    } catch (error) {
      console.error('Error fetching notifications:', error);
      return rejectWithValue(error.response?.data || 'Ошибка загрузки уведомлений');
    }
  }
);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    loansCount: 0, // Количество займов
    withdrawalsCount: 0, // Количество выводов
    loading: false,
    error: null,
  },
  reducers: {
    resetNotifications(state) {
      state.loansCount = 0;
      state.withdrawalsCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.loansCount = action.payload.loans_count || 0;
        state.withdrawalsCount = action.payload.withdrawals_count || 0;
        state.loading = false;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetNotifications } = notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;
