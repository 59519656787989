import { useEffect, useState } from "react";
import axios from "axios";

const useAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem("jwtToken");
            if (token) {
                try {
                    await axios.get("/node/api/verify-token", {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setIsAuthenticated(true);
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        console.error("Неавторизованный доступ.");
                        setIsAuthenticated(false);
                        localStorage.removeItem("jwtToken");
                    } else {
                        console.error("Произошла непредвиденная ошибка:", error.message);
                        setIsAuthenticated(false);
                    }
                }
            } else {
                setIsAuthenticated(false);
            }
            setLoading(false);
        };

        checkAuth();
    }, []);

    return { isAuthenticated, loading };
};

export default useAuth;


// import axios from "axios";

// const checkAuth = () => {
//     const TOKEN = localStorage.getItem("token");
//     const PUBLIC_ROUTES = ["login", "forgot-password", "register", "documentation"];

//     const isPublicPage = PUBLIC_ROUTES.some(r => window.location.href.includes(r));

//     if (!TOKEN && !isPublicPage) {
//         window.location.href = '/login';
//         return;
//     } else {
//         axios.interceptors.request.use(function (config) {
//             document.body.classList.add('loading-indicator');
//             if (TOKEN) {
//                 config.headers.Authorization = `Bearer ${TOKEN}`;
//             }
//             return config;
//         }, function (error) {
//             return Promise.reject(error);
//         });

//         axios.interceptors.response.use(function (response) {
//             document.body.classList.remove('loading-indicator');
//             return response;
//         }, function (error) {
//             document.body.classList.remove('loading-indicator');
//             if (error.response && error.response.status === 401) {
//                 localStorage.removeItem("token");
//                 window.location.href = '/login';
//             }
//             return Promise.reject(error);
//         });

//         return TOKEN;
//     }
// }

// export default checkAuth;
